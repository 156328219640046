import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <>
      <div className="message">
        <div className="texts">
          <div className="text text-front">Gopalan Aarogyam</div>
          <div className="text text-back">coming soon</div>
        </div>
      </div>
    </>
  );
}

export default App;
